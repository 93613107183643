import { UserVehicle } from 'shared/types/models';
import Rest from '../services/rest';
import { RebateCategoryAnswer } from '../shared/types/common';
import {
  getRebatesCategoriesFromAnswersUsingQuestions,
  rebatesCategoriesQuestions,
} from '../shared/utils/functions';
import omit from 'lodash/omit';

const rest = new Rest();

const isPermissionMismatchError = (error) =>
  error.message === 'HTTP error, status = 403';

export const updateUserAllRebates: (
  rebatesNames: string[],
  rebatesCategoriesAnswers: RebateCategoryAnswer[],
) => Promise<void> = async (
  rebatesNames,
  rebatesCategoriesAnswers,
): Promise<void> => {
  if (!rebatesCategoriesAnswers?.length && !rebatesNames?.length) return;
  await rest.updateUserProfile({
    ...(rebatesCategoriesAnswers?.length
      ? { rebates_categories_answers: rebatesCategoriesAnswers }
      : {}),
    ...(rebatesNames?.length ? { rebates_names: rebatesNames } : {}),
  });

  const msRebatesCategories = await rest.getMsRebatesCategories();
  const rebatesCategories = getRebatesCategoriesFromAnswersUsingQuestions(
    rebatesCategoriesQuestions,
    rebatesCategoriesAnswers || [],
    msRebatesCategories,
  );

  try {
    await rest.updateUserActiveBidsRebatesCategories({
      rebatesCategories: rebatesCategories || [],
      rebatesNames: rebatesNames || [],
    });
  } catch (error) {
    // TODO: handle error properly
    // if (!isPermissionMismatchError(error)) throw error;
  }
};

export const updateUserVehicleOwn = async (
  vehicleOwn?: UserVehicle[],
): Promise<void> => {
  if (!vehicleOwn?.length) return;
  try {
    await rest.updateUserVehicles({
      // Always override existing vehicles to make sure there is no collision
      // between users that use one PC
      vehicles: vehicleOwn.map((vehicle) => omit(vehicle, 'id')),
    });
  } catch (error) {
    if (!isPermissionMismatchError(error)) throw error;
  }
};
