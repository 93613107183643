import Login from 'containers/auth/Login/LoginForm';
import { FC, useRef } from 'react';
import Layout from 'containers/Layout';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { auth } from 'services/auth';
import Rest from 'services/rest';
import MFADialog, { IConfirmMFA } from './MFADialog';
import { useRouter } from 'next/router';
import ConfirmIdentityDialog, {
  IConfirmIdentityDialog,
  ViewMode,
} from './ConfirmIdentityDialog';
import { useRecoilValueLoadable, useSetRecoilState } from 'recoil';
import { userAtom, userClientRebatesNamesAtom } from 'recoil/atoms';
import { afterLoginRedirect } from '../Signup/utils';
import {
  EMAIL_CONFIRMATION_NOTE,
  EMAIL_CONFIRMATION_SPAN_NOTICE,
  EMAIL_CONFIRMATION_TITLE,
} from '../../../data/constants';
import Head from 'next/head';
import { SEO_LOGIN_PAGE } from 'utils/seo';
import {
  updateUserAllRebates,
  updateUserVehicleOwn,
} from '../../../utils/rebatesHelpers';
import {
  userRebatesCategoriesAnswersSelector,
  userVehicleOwnSelector,
} from '../../../recoil/selectors';
import { hasPermission } from 'shared/utils/functions';
import { Permission } from 'shared/types/models';
import { styled } from '@mui/material/styles';

const rest = new Rest();

const Wrapper = styled('div')(({ theme }) => ({
  width: 320,
  margin: '0 auto',
  paddingTop: theme.spacing(4),
}));

const LoginPageSeoHead: FC = () => {
  return (
    <Head>
      <title>{SEO_LOGIN_PAGE.title()}</title>
      <meta name='description' content={SEO_LOGIN_PAGE.description()} />
      <meta name='keywords' content={SEO_LOGIN_PAGE.keywords()} />
    </Head>
  );
};

export const LoginPage: FC = () => {
  const mFADialog = useRef<IConfirmMFA>(null);
  const confirmIdentityDialog = useRef<IConfirmIdentityDialog>(null);
  const router = useRouter();
  const setUser = useSetRecoilState(userAtom);

  const rebatesAnswers = useRecoilValueLoadable(
    userRebatesCategoriesAnswersSelector,
  ).valueMaybe();
  const rebatesNames = useRecoilValueLoadable(
    userClientRebatesNamesAtom,
  ).valueMaybe();
  const vehicleOwn = useRecoilValueLoadable(
    userVehicleOwnSelector,
  ).valueMaybe();

  async function handleLogin(email: string, password: string): Promise<void> {
    await auth.login(email, password, mFADialog?.current?.getCode);
    await Promise.all([
      updateUserAllRebates(rebatesNames, rebatesAnswers),
      updateUserVehicleOwn(vehicleOwn),
    ]);
    const user = await rest.getUserProfile();
    setUser(user);
    const isConsumer = hasPermission(user, Permission.user);

    if (isConsumer) {
      rest.deanonymizeAuctionVisits().catch((e) => {
        console.error(
          `Failed to deanonymize auction visits, user_id: ${user.id}`,
          e,
        );
      });
    }

    afterLoginRedirect(user, router);
  }

  return (
    <>
      <LoginPageSeoHead />
      <Layout hideFooter>
        <MFADialog ref={mFADialog} />
        <ConfirmIdentityDialog
          viewMode={ViewMode.EMAIL}
          ref={confirmIdentityDialog}
          title={EMAIL_CONFIRMATION_TITLE}
          text={EMAIL_CONFIRMATION_NOTE}
          secondaryText={EMAIL_CONFIRMATION_SPAN_NOTICE}
        />
        <Wrapper>
          <Box mb={3}>
            <Typography variant='h5' component='h1' gutterBottom align='center'>
              Log in or create an account
            </Typography>
          </Box>
          <Login
            login={handleLogin}
            onConfirmationRequired={async (email, password) => {
              let error;

              const confirmed =
                await confirmIdentityDialog.current.requestConfirmation({
                  email,
                  password,
                });

              if (confirmed) {
                try {
                  await handleLogin(email, password);
                } catch (e) {
                  error = e;
                }
              }

              return { confirmed, error };
            }}
            showPasswordByDefault={true}
          />
        </Wrapper>
      </Layout>
    </>
  );
};
