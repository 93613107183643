import { useState } from 'react';
import { Form } from 'react-final-form';
import { LoginAlert } from '../../../components/molecules/Auth/components';
import { User } from 'shared/types/models';
import Mui5Link from '../../../components/atoms/Link';
import NextLinkMui5 from 'components/atoms/NextLink';
import ProgressButton from 'components/atoms/ProgressButton';
import FieldText from 'containers/profile/FieldText';
import { auth, removeCognitoCookies } from 'services/auth';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { isEmail } from 'utils/validators';
import { EventSignupRequest } from '../types';
import { NextRouter, useRouter } from 'next/router';
import { GetInTouch } from '../../../components/atoms/GetInTouch';

interface FormValues {
  email?: string;
  password?: string;
}

type Error = {
  code: string;
  message: string;
  name: string;
};

type onConfirmationRequiredResult = {
  confirmed: boolean;
  error: undefined | Error;
};

type Props = Partial<EventSignupRequest> & {
  login: (email: string, password: string) => Promise<User | void>;
  onConfirmationRequired: (
    email: string,
    password: string,
  ) => Promise<onConfirmationRequiredResult>;
  showPasswordByDefault?: boolean;
};

const StyledForm = styled('form')(({ theme }) => ({
  '& .MuiFormControl-root': {
    marginBottom: theme.spacing(3),
  },
}));

const getSignupLink = (router: NextRouter): string => {
  const returnTo = router.query.returnTo;
  return `/signup${returnTo ? `?returnTo=${returnTo}` : ''}`;
};

export default function Login({
  login,
  onConfirmationRequired,
  onSignupRequest,
  showPasswordByDefault = false,
}: Props): JSX.Element {
  const router = useRouter();

  const [error, setError] = useState('');
  const [submitText, setSubmitText] = useState(
    showPasswordByDefault ? 'Login' : 'Continue with email',
  );
  const [showPassword, setShowPassword] = useState<boolean>(
    showPasswordByDefault,
  );
  const initialValues: FormValues = { email: '', password: '' };

  function validate(values: FormValues) {
    const errors: FormValues = {};
    if (!values.email) {
      errors.email = 'Email required';
    } else if (!isEmail(values.email.trim())) {
      errors.email = 'Invalid email address';
    }

    if (showPassword && !values.password) {
      errors.password = 'Password is required';
    }
    return errors;
  }

  async function onSubmitForm(data: FormValues) {
    setError(null);
    const email = data.email.trim();

    if (showPassword) {
      removeCognitoCookies();
      return login(email, data.password).catch(async (error) => {
        if (error.code === 'CodeMismatchException') {
          error.message = 'Incorrect MFA code';
        }
        setError(error.message);
        if (error.code === 'UserNotConfirmedException') {
          const res = await onConfirmationRequired(data.email, data.password);

          if (res.error) {
            setError(res.error.message);
          }
        }
      });
    } else {
      try {
        const isOurMember = !(await auth.isEmailAvailable(email));

        if (isOurMember) {
          setShowPassword(true);
          setSubmitText('Login');
        } else {
          onSignupRequest({
            email,
          });
        }
      } catch (e) {
        setError(e.message);
      }
    }
  }

  return (
    <Form
      onSubmit={onSubmitForm}
      initialValues={initialValues}
      validate={validate}
      render={({ handleSubmit, submitting }) => (
        <StyledForm
          className={'js-login-form'}
          onSubmit={handleSubmit}
          noValidate
        >
          {error && <LoginAlert severity='error'>{error}</LoginAlert>}
          <FieldText name='email' label='Email' autoComplete='new-email' />
          {showPassword && (
            <>
              <FieldText
                name='password'
                label='Password'
                type='password'
                autoComplete='password'
              />
              <Box mt={-2} mb={2}>
                <Mui5Link component={NextLinkMui5} href='/forgot-password'>
                  Forgot Password?
                </Mui5Link>
              </Box>
            </>
          )}
          <ProgressButton
            className='js-login-submit-button'
            color='primary'
            variant='contained'
            size='medium'
            type='submit'
            onClick={handleSubmit}
            loading={submitting}
            fullWidth
          >
            {submitText}
          </ProgressButton>
          <Box my={2}>
            Don&apos;t have an account yet?{' '}
            <Mui5Link component={NextLinkMui5} href={getSignupLink(router)}>
              Sign Up
            </Mui5Link>
          </Box>
          <Box my={2}>
            <GetInTouch />
          </Box>
          <Box my={2}>
            <Typography variant='body2'>
              By signing in or creating an account, you agree with our{' '}
              <Mui5Link component={NextLinkMui5} href='/terms'>
                Terms & Conditions
              </Mui5Link>
            </Typography>
          </Box>
        </StyledForm>
      )}
    />
  );
}
