import {
  forwardRef,
  ForwardRefRenderFunction,
  useImperativeHandle,
  useState,
} from 'react';
import { Form } from 'react-final-form';
import {
  DialogActionsContainer,
  LoginAlert,
  LoginTextField,
  Title,
} from '../../../components/molecules/Auth/components';

import ProgressButton from 'components/atoms/ProgressButton';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { useScreenSize } from 'hooks/useScreenSize';

interface mfaData {
  mfaCode?: string;
}

type Props = {
  // onConfirmed: (code) => void;
  // onClose: () => void;
};

export interface IConfirmMFA {
  getCode(): Promise<string>;
}

let confirmMFA;

const ConfirmMFA: ForwardRefRenderFunction<IConfirmMFA, Props> = (
  props,
  ref,
): JSX.Element => {
  const initialValues: mfaData = { mfaCode: '' };
  const [isOpened, toggleIsOpened] = useState<boolean>(false);

  useImperativeHandle(ref, () => ({
    getCode,
  }));

  async function getCode(): Promise<string> {
    toggleIsOpened(true);
    return new Promise((resolve) => {
      confirmMFA = (data: { mfaCode: string }) => {
        toggleIsOpened(false);
        resolve(data.mfaCode);
      };
    });
  }

  function validate(values: mfaData) {
    const errors: mfaData = {};
    if (!values.mfaCode) {
      return { mfaCode: 'Required' };
    }
    return errors;
  }

  const { isExtraSmallScreen } = useScreenSize();

  return (
    <Dialog
      open={isOpened}
      onClose={() => confirmMFA({ mfaCode: '' })}
      fullScreen={isExtraSmallScreen}
    >
      <Form
        onSubmit={(data) => confirmMFA(data)}
        initialValues={initialValues}
        validate={validate}
        render={({ handleSubmit, submitting, submitError }) => (
          <form autoComplete='off' onSubmit={handleSubmit} noValidate>
            <DialogContent>
              {submitError && (
                <LoginAlert severity='error'>{submitError}</LoginAlert>
              )}

              <Title variant='h5'>Enter the code you received in SMS</Title>

              <LoginTextField name='mfaCode' label='MFA Code' />

              <DialogActionsContainer>
                <ProgressButton
                  color='primary'
                  type='submit'
                  onClick={handleSubmit}
                  loading={submitting}
                >
                  confirm
                </ProgressButton>
              </DialogActionsContainer>
            </DialogContent>
          </form>
        )}
      />
    </Dialog>
  );
};

export default forwardRef(ConfirmMFA);
