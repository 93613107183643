import { LoginPage } from 'containers/auth/Login/LoginPage';
import { DefaultPageProps } from 'pages';
import { FC } from 'react';
import authorized from 'utils/authorized';

type LoginPageProps = DefaultPageProps;

const LoginPageWrapper: FC<LoginPageProps> = () => {
  return <LoginPage />;
};

export const getServerSideProps = authorized<LoginPageProps>(
  async (ctx, rest, auth, user) => {
    return {
      props: {
        user,
      },
    };
  },
);

export default LoginPageWrapper;
